<template>
  <div>
    <KTCard ref="preview" id="fund-account-card" v-bind:title="title" headClass="pt-6 pb-6 pl-10">
      <!--页面标题-->
      <template v-slot:title>
        <b-row>
          <h3>
            <b-icon icon="blockquote-right" class="mx-3"></b-icon>
            资金账户管理
          </h3>
        </b-row>

        <b-row>
          <div class="example-tools justify-content-center">
            <div class="d-flex align-items-center flex-wrap mt-7">
              <div class="d-flex align-items-center flex-lg-fill mr-10 mb-2">
                <span class="mr-4">
                  <i class="flaticon-profile-1 display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">商户名称</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">
                      {{ accountInfo.merchantName }}
                    </span>
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-10 mb-2">
                <span class="mr-4">
                  <i class="flaticon-piggy-bank display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">账户余额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>
                    {{ accountInfo.balance / 100.00 }}
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-10 mb-2"><span class="mr-4"><i
                    class="flaticon2-protected display-3 text-muted font-weight-bold"></i></span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">冻结余额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>
                    {{ accountInfo.freezeBalance / 100.00 }}
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-coins display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">待结算金额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.unSettleAmount /100.0}}
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-10 mb-2 border-right pr-6">
                <span class="mr-4">
                  <i class="flaticon-information display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column flex-lg-fill">
                  <span class="text-dark-75 font-weight-bolder font-size-sm">提现状态</span>
                  <v-switch @change="changeWithdrawStatus" class="mt-1" color="success" value="success"
                    v-model="accountInfo.status" :true-value="1" :false-value="0" hide-details>
                    <template v-slot:label class="pb-0">
                      <span class="text-dark-50 font-weight-bolder font-size-sm">
                        开启
                      </span>
                    </template>
                  </v-switch>
                </div>
              </div>


              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 ">
                <!--按钮组-->
                <b-button variant="primary" @click="addFund()" class="mx-2">
                  <b-icon icon="plus"></b-icon>
                  充值
                </b-button>

                <!--按钮组-->
                <b-button variant="warning" @click="detectFund()" class="mx-2">
                  <i class="fas fa-minus"></i>
                  扣款
                </b-button>
                <!--按钮组-->
              </div>
            </div>
          </div>
        </b-row>
      </template>


      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 60vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="60vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="id">

            <template v-slot:item.type="{ item }">
              {{ filter.typeOptions.find((typeItem) => typeItem.value == item.type).text }}
            </template>

            <template v-slot:item.amount="{ item }">
              {{ item.amount / 100.00 }}
            </template>

            <template v-slot:item.originAmount="{ item }">
              {{ item.originAmount / 100.00 }}
            </template>

            <template v-slot:item.createTime="{ item }">
              {{ item.createTime | formatDate }}
            </template>



            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group class="mx-2">
                  <b-form-input id="input-2" v-model="filter.orderId" placeholder="订单号"></b-form-input>
                </b-form-group>
                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.typeOptions" v-model="filter.type"></b-form-select>
                </b-form-group>

                <b-form-datepicker locale="zh" v-model="filter.startTime" placeholder="起始日期" :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"></b-form-datepicker>

                <b-form-datepicker class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0" locale="zh"
                  v-model="filter.endTime" placeholder="结束日期" :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"></b-form-datepicker>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getBills()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>



    <!--模态框-加扣款-->
    <b-modal ref="fund-modal" static="true" centered title="资金操作">
      <b-container fluid>
        <b-form id="fundForm" method="POST" class="form" novalidate="novalidate">
          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">金额:</label>
            </b-col>
            <b-col cols="9">
              <b-form-group>
                <b-form-input type="number" name="amount" v-model="formModel.amount" placeholder="请输入金额...">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-0">
            <b-col cols="3" class="text-right">
              <label class="form-group-label">备注:</label>
            </b-col>
            <b-col cols="9">
              <b-form-group>
                <b-form-input type="text" name="remark" v-model="formModel.remark" placeholder="请输入备注...">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <!---->
      <template v-slot:modal-footer>
        <b-button v-on:click="onSubmit" variant="primary">提交
        </b-button>
        <b-button v-on:click="$refs['fund-modal'].hide()">取消</b-button>
      </template>
    </b-modal>
    <!--模态框-审核-->
  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {
      merchantId: "",
      accountInfo: {},
      formModel: {
        merchantId: "",
        type: "",
        amount: "",
        remark: "",
      },
      filter: {
        typeOptions: [{
          value: null,
          text: "类型"
        },
        {
          value: 1,
          text: "订单结算"
        },
        {
          value: 2,
          text: "商户提现"
        },
        {
          value: 3,
          text: "加款"
        },
        {
          value: 4,
          text: "扣款"
        },
        {
          value: 5,
          text: "冻结"
        },
        {
          value: 6,
          text: "解冻"
        },
        ],
        type: null,
        orderId:"",
        startTime: "",
        endTime: "",
      },

      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [{
          text: "商户ID",
          value: "merchantId"
        },
        {
          text: "操作类型",
          value: "type"
        },
        {
          text: "原金额",
          value: "originAmount"
        },
        {
          text: "金额",
          value: "amount"
        },
        {
          text: "操作时间",
          value: "createTime"
        },
        {
          text: "备注",
          value: "remark"
        }
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {},
        desserts: [],
      },
    };
  },
  mounted() {
    let fundForm = KTUtil.getById("fundForm");
    this.fv = formValidation(fundForm, {
      fields: {
        amount: {
          validators: {
            notEmpty: {
              message: "金额不能为空!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  created() {
    this.merchantId = this.$route.query.merchantId;
    this.getAccount();
  },

  methods: {
    changeWithdrawStatus() {
      let that = this;
      ApiService.post("/api/merchant/account/manage/update", {
        merchantId: that.merchantId,
        status: that.accountInfo.status,
      }).then(({
        data
      }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "修改成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            title: "修改失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },

    addFund() {
      this.formModel.merchantId = this.merchantId;
      this.formModel.type = 3;
      this.$refs["fund-modal"].show();
    },

    detectFund() {
      this.formModel.merchantId = this.merchantId;
      this.formModel.type = 4;
      this.$refs["fund-modal"].show();
    },


    getAccount() {
      let that = this;
      ApiService.query("/api/merchant/account/query_by_id", {
        params: {
          merchantId: that.merchantId
        },
      }).then(({
        data
      }) => {
        that.accountInfo = data.result;
      });
    },

    getBills() {
      let params = {
        merchantId: this.merchantId,
        orderId:this.filter.orderId,
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        type: this.filter.type,
        startTime: this.filter.startTime,
        endTime: this.filter.endTime,

      };

      ApiService.query("/api/merchant/account/bills/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.id);
        });
      }
      return ids;
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post("/api/merchant/account/manage/fundOperate", that.formModel).then(({
            data
          }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "提交成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getAccount();
              that.getBills();
              that.$refs["fund-modal"].hide();
            } else {
              Swal.fire({
                title: "提交失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getBills();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}




#fund-account-card {
  &.card.card-custom>.card-header .card-title {
    display: inline-block !important;
    width: 70%;
  }
}
</style>